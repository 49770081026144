import Vue from "vue";
import VueRouter from "vue-router";
import FunTopicComponent from "@/components/FunTopicComponent";
import TopicDetailsComponent from "@/components/TopicDetailsComponent";
import LoginComponent from "@/components/LoginComponent.vue";
import RegisterComponent from "@/components/RegisterComponent.vue";
import GoogleRegisterComponent from "@/components/GoogleRegisterComponent.vue";
import DashboardPage from "@/components/DashboardComponent.vue";
import TopicPage from "@/components/TopicComponent.vue";
import Terms from "@/components/TermAndCondition.vue";
import Privacy from "@/components/PrivacyPolicy.vue";
import TopicDetailsComponentNew from "@/components/TopicDetailsComponentNew";
import ParentOnboardComponent from "@/components/ParentOnboardComponent";
import HomePage from "@/components/HomePage";
import ParentDashboardComponent from "@/components/ParentDashboard/ParentDashboardComponent"
import ForgotPasscodeComponent from "@/components/ParentPasscode/ForgotPasscodeComponent.vue";
import SetupPasscodeComponent from "@/components/ParentPasscode/SetupPasscodeComponent.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: LoginComponent,
  },
  {
    path: "/",
    name: "Login",
    component: LoginComponent,
  },
  {
    path: "/register",
    name: "Register",
    component: RegisterComponent,
  },
  {
    path: "/gisregister",
    name: "GoogleRegisterComponent",
    component: GoogleRegisterComponent,
  },
  {
    path: "/terms",
    name: "Terms",
    component: Terms,
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: DashboardPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/home",
    name: "HomePage",
    component: HomePage,
    meta: { requiresAuth: true },
  },
  {
    path: "/topic",
    name: "Topic",
    component: TopicPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/topic_details",
    name: "topicDetails",
    component: TopicDetailsComponent,
    meta: { requiresAuth: true },
  },
  {
    path: "/fun",
    name: "Fun",
    component: FunTopicComponent,
    meta: { requiresAuth: true },
  },
  {
    path: "/topic_details_new",
    name: "TopicDetailsComponentNew",
    component: TopicDetailsComponentNew,
    meta: { requiresAuth: true },
  },
  {
    path: "/parent_onboard",
    name: "ParentOnboardComponent",
    component: ParentOnboardComponent,
    meta: { requiresAuth: true },
  },
  {
    path: "/parent_dashboard",
    name: "ParentDashboardComponent",
    component: ParentDashboardComponent,
    meta: { requiresAuth: true },
  },
  {
    path: "/forgot_passcode",
    name: "ForgotPasscodeComponent",
    component: ForgotPasscodeComponent,
    meta: { requiresAuth: true },
  },
  {
    path: "/setup_passcode",
    name: "SetupPasscodeComponent",
    component: SetupPasscodeComponent,
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

let hideNavbar = false;

router.beforeEach((to, from, next) => {
    if (to.path === "/setup_passcode") {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");

      if (token) {
        // Store the token temporarily (e.g., in a variable or Vuex)
        localStorage.setItem("setup_passcode_token", token);   
      }

      // Redirect to login if the user isn't logged in
      const isLoggedIn = localStorage.getItem("isLoggedIn");
      if (!isLoggedIn) {
        next("/login"); // Redirect to login
      } else {
        next(); // Proceed to /setup_passcode
      }
    } else {
      next(); // Proceed as normal
    }
  const token = localStorage.getItem("token");
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  // Handle back button navigation for specific route
  if (from.path === "/parent_onboard" && to.path !== "/home") {
    next(false); // Block navigation
  } else {
    next(); // Allow navigation
  }

  if (requiresAuth && !token) {
    next("/login");
  } else if (
    (to.name === "Login" ||
      to.name === "Register" ||
      to.name === "GoogleRegisterComponent") &&
    token
  ) {
    next("/home");
  } else {
    if (
      to.name === "Login" ||
      to.name === "Register" ||
      to.name === "GoogleRegisterComponent"
    ) {
      hideNavbar = true;
    } else {
      hideNavbar = false;
    }
    next();
  }
});

export { router, hideNavbar };
