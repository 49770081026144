<template>
  <b-button :class="['btn', btnClass]" @click="handleClick">
    <slot></slot>
  </b-button>
</template>

<script>
export default {
  name: "ButtonComponent",
  props: {
    btnClass: {
      type: String,
      default: "", // Default CSS class for the button
    },
  },
  methods: {
    handleClick() {
      // Emit a click event
      this.$emit("click");
    },
  },
};
</script>

<style scoped>
.btn {
  width: 100%;
  font-size: 18px;
  line-height: normal;
  border-radius: 10px;
  padding: 21px, 174px;
  background: #ede7d2;
  border: none;
  height: 60px;
  margin-top: 20px;
  color: #323232; /* Text color when disabled */
  font-style: normal;
  font-weight: 400;
}

.btn:hover {
  color: #333; /* Ensure text stays black on hover */
}
.btn:disabled {
  background: #ede7d2;
  color: black; /* Text color when disabled */
  cursor: not-allowed; /* Change cursor to indicate disabled state */
  opacity: 0.4;
}

.btn:disabled:hover {
  background: #ede7d2;
  color: black; /* Ensure text color stays black on hover */
}
/* Add scoped CSS styles for your button */
</style>
