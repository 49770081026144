<template>
  <div>
    <div v-if="!isLoading">
      <div
        class="margin-top"
        v-if="
          this.weekly_data.length === 0 &&
          this.cumulative_data.length === 0 &&
          this.weeklyAvgEngagement === 0 &&
          this.noOfVideoCompleted === 0
        "
      >
        <div class="noRecord">
          Feature has not been used for the last 7 days. The section will update
          once data is available.
        </div>
      </div>
      <div v-else>
        <!-- Date Selector -->
        <div class="col-12 p-0 date-selector-container margin-bottom">
          <!-- <button class="right-arrow-btn" @click="changeWeek(-1)">
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.0933 7.82812L11.9214 15L19.0933 22.1719L16.8745 24.375L7.49951 15L16.8745 5.625L19.0933 7.82812Z"
            fill="#323232"
          />
        </svg>
      </button> -->
          <div class="date-display">{{ formattedDateRange }}</div>
          <!-- <button class="left-arrow-btn" @click="changeWeek(1)">
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.9067 22.1719L18.0786 15L10.9067 7.82812L13.1255 5.625L22.5005 15L13.1255 24.375L10.9067 22.1719Z"
            fill="#323232"
          />
        </svg>
      </button> -->
        </div>
        <!-- Summary Section -->
        <div class="col-12 p-0 summary-container margin-bottom">
          <p class="m-0">
            {{ this.parent_dash_text }}
          </p>
        </div>
        <!-- Graphs -->
        <div class="row m-0 margin-bottom">
          <div class="col-6 pl-0">
            <div class="chart-card">
              <div
                class="weekly-chart-text row m-0 d-flex justify-content-center"
              >
                <div class="col-8">
                  Weekly {{ deviceType === "Desktop" ? "percentage" : "%" }} of
                  modules covered by subject
                </div>
              </div>
              <div v-if="this.weekly_data.length > 0">
                <DonutChart :chartData="this.weekly_data" />
              </div>
              <div class="noDataFound" v-else>No Data Found</div>
            </div>
          </div>
          <div class="col-6 pr-0">
            <div class="chart-card">
              <div
                class="weekly-chart-text row m-0 d-flex justify-content-center"
              >
                <div class="col-8">
                  Cumulative
                  {{ deviceType === "Desktop" ? "percentage" : "%" }} of modules
                  covered by subject
                </div>
              </div>
              <div v-if="this.cumulative_data.length > 0">
                <DonutChart :chartData="this.cumulative_data" />
              </div>
              <div class="noDataFound" v-else>No Data Found</div>
            </div>
          </div>
        </div>
        <!-- Stats -->
        <div class="row m-0 margin-bottom card-container">
          <div class="col-4 col-sm-4 col-md-4 col-lg-4">
            <div class="card-1 p-3" :style="{backgroundColor: '#7DD9CC'}">
              <div class="number">
                {{ this.weeklyAvgEngagement }}
                <div class="mins-text">mins</div>
              </div>
              <div class="number-text">
                Avg. time spent on app each day of week
              </div>
            </div>
          </div>
          <div class="col-4 col-sm-4 col-md-4 col-lg-4">
            <div class="card-1 p-3" :style="{backgroundColor: '#F29D63'}">
              <div class="number">{{ this.noOfVideoCompleted }}</div>
              <div class="number-text">Video modules completed</div>
            </div>
          </div>
          <div class="col-4 col-sm-4 col-md-4 col-lg-4">
            <div class="card-1 p-3" :style="{backgroundColor: '#C1DC46'}">
              <div class="number">
                {{ this.noOfFunAskPerWeek ? this.noOfFunAskPerWeek : 0 }}
              </div>
              <div class="number-text">
                Questions asked & answered in fun section
              </div>
            </div>
          </div>
        </div>
        <!-- Average Scores -->
        <div class="row m-0 average-score-container">
          <!-- Progress Bars -->
          <div v-if="this.bar_chart_data.length > 0">
          <HorizontalBarChart :chartData="this.bar_chart_data" @update-height="chartHeight = $event" />
          <div class="vertical-bar" :style="{ height: chartHeight + 'px' }"></div>
          <div class="horizontal-line"></div>

          <!-- Text below the horizontal line -->
          <div class="footer-text">Average scores per subject</div>
          </div>
          <div class="text-center" v-else>No Data Found</div>
        </div>
      </div>
    </div>
    <loading
      :active="isLoading"
      color="#9651F1"
      backgroundColor="#C9C9C9"
      :height="100"
      :width="100"
    />
  </div>
</template>

<script>
import axios from "axios";
import DonutChart from "./DonutChart.vue";
import HorizontalBarChart from "./HorizontalBarChart.vue";
import Loading from "vue-loading-overlay";
import { mapGetters } from "vuex";
import { LABELS } from "@/constants/labels";
import { format, subDays } from "date-fns";

export default {
  components: { DonutChart, HorizontalBarChart, Loading },
  name: "SummaryComponent",
  data() {
    return {
      userId: localStorage.getItem("userId"),
      // currentDate: new Date(), // Current selected date
      startDate: subDays(new Date(), 7), // 7 days before the current date,
      endDate: new Date(), // Current Date
      // weeklyData: null,
      isLoading: false,
      parent_dash_text: "",
      noOfVideoCompleted: null,
      noOfFunAskPerWeek: null,
      weeklyAvgEngagement: null,
      cumulative_data: [],
      weekly_data: [],
      bar_chart_data: [],
      labels: LABELS,
      chartHeight: 0,// Dynamically updated height
    };
  },
  computed: {
    // Safely format the start date of each week
    // formattedStartDate() {
    //   return this.startDate
    //     ? this.startDate.toLocaleDateString("en-US", {
    //         month: "short",
    //         day: "numeric",
    //         year: "numeric",
    //       })
    //     : "";
    // },
    // this will calculate 7 days before the current date
    formattedDateRange() {
      // Format both start and end dates
      const formattedStart = format(this.startDate, "MMM d, yyyy");
      const formattedEnd = format(this.endDate, "MMM d, yyyy");
      return `From ${formattedStart} To ${formattedEnd}`;
    },
    // Map Vuex getter to access subjects
    ...mapGetters(["getSubjects"]),
    subjects() {
      return this.getSubjects; // Access subjects from Vuex
    },

    deviceType() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        return "Android";
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }
      return "Desktop";
    },
  },
  mounted() {
    // Initialize the current week's dates
    // this.calculateWeek(this.currentDate);
    this.fetchParentDashbordApi();
  },
  methods: {
    calculateWeek(date) {
      let dayIndex = date.getDay(); // Get day of the week (0 for Sunday, 1 for Monday, etc.)
      dayIndex = dayIndex === 0 ? 6 : dayIndex - 1; // Adjust so 0 is Monday, 1 is Tuesday, ..., 6 is Sunday

      const start = new Date(date);
      start.setDate(date.getDate() - dayIndex); // Calculate start of the week (Monday)

      const end = new Date(start);
      end.setDate(start.getDate() + 6); // Calculate end of the week (Sunday)

      this.startDate = start;
      this.endDate = end;
    },
    changeWeek(direction) {
      // Change the week by adding or subtracting 7 days
      this.currentDate.setDate(this.currentDate.getDate() + direction * 7);
      this.calculateWeek(this.currentDate);
    },
    fetchParentDashbordApi() {
      this.isLoading = true;
      //weekly_llm_summery
      const weeklyLLMSummary = process.env.VUE_APP_FLUTE_N_FEATHER_PARENT_DASHBOARD_BASE_API_URL_DOMAIN + process.env.VUE_APP_FLUTE_N_FEATHER_WEEKLY_LLM_SUMMARY_URL_PATH + `/${this.userId}`;
      //weekly_percentage_by_subject
      const weeklyPercentageUrl =
        process.env
          .VUE_APP_FLUTE_N_FEATHER_PARENT_DASHBOARD_BASE_API_URL_DOMAIN +
        process.env
          .VUE_APP_FLUTE_N_FEATHER_WEEKLY_PERCENTAGE_BY_SUBJECT_URL_PATH +
        `/${this.userId}`;
      //cumulative_percentage_by_subject
      const cumulativePercentageUrl =
        process.env
          .VUE_APP_FLUTE_N_FEATHER_PARENT_DASHBOARD_BASE_API_URL_DOMAIN +
        process.env
          .VUE_APP_FLUTE_N_FEATHER_CUMULATIVE_PERCENTAGE_BY_SUBJECT_URL_PATH +
        `/${this.userId}`;
      //get_completed_videos_for_user
      const completedVideosUrl =
        process.env
          .VUE_APP_FLUTE_N_FEATHER_PARENT_DASHBOARD_BASE_API_URL_DOMAIN +
        process.env.VUE_APP_FLUTE_N_FEATHER_VIDEO_MODULE_COMPLETE_URL_PATH +
        `/${this.userId}`;
      //weekly_avg_fun_interactions
      const weeklyAvgFunInteractionsUrl =
        process.env
          .VUE_APP_FLUTE_N_FEATHER_PARENT_DASHBOARD_BASE_API_URL_DOMAIN +
        process.env.VUE_APP_FLUTE_N_FEATHER_WEEKLY_AVG_FUN_QUS_URL_PATH +
        `/${this.userId}`;
      //weekly_avg_engagement
      const weeklyAvgEngagementUrl =
        process.env
          .VUE_APP_FLUTE_N_FEATHER_PARENT_DASHBOARD_BASE_API_URL_DOMAIN +
        process.env.VUE_APP_FLUTE_N_FEATHER_WEEKLY_AVG_ENGAGEMENT_URL_PATH +
        `/${this.userId}`;
      //weekly_avg_quiz
      const weeklyAvgQuizUrl =
        process.env
          .VUE_APP_FLUTE_N_FEATHER_PARENT_DASHBOARD_BASE_API_URL_DOMAIN +
        process.env.VUE_APP_FLUTE_N_FEATHER_WEEKLY_AVG_QUIZ_URL_PATH +
        `/${this.userId}`;
      // API calls with individual then/catch blocks
      const apiCalls = [
        axios
          .get(weeklyPercentageUrl)
          .then((response) => {
            const subjectList = this.subjects;
            const weeklyPercentageData =
              response.data.weekly_percentage_by_subject;
            this.weekly_data = this.mapSubjectData(
              weeklyPercentageData,
              subjectList
            );
          })
          .catch((error) => {
            console.error("Error fetching weekly percentage data:", error);
          }),
        axios
          .get(weeklyLLMSummary)
          .then((response) => {
            this.parent_dash_text = response.data.weekly_summary
          })
          .catch((error) => {
            console.error("Error fetching weekly percentage data:", error);
          }),
        axios
          .get(cumulativePercentageUrl)
          .then((response) => {
            const subjectList = this.subjects;
            const cumulativePercentageData =
              response.data.weekly_percentage_by_subject;
            this.cumulative_data = this.mapSubjectData(
              cumulativePercentageData,
              subjectList
            );
          })
          .catch((error) => {
            console.error("Error fetching cumulative percentage data:", error);
          }),
        axios
          .get(completedVideosUrl)
          .then((response) => {
            this.noOfVideoCompleted = Math.round(
              response.data.total_videos_completed
            );
          })
          .catch((error) => {
            console.error("Error fetching completed videos data:", error);
          }),
        axios
          .get(weeklyAvgFunInteractionsUrl)
          .then((response) => {
            this.noOfFunAskPerWeek = Math.round(response.data.weekly_average);
          })
          .catch((error) => {
            console.error(
              "Error fetching weekly average fun interactions data:",
              error
            );
          }),
        axios
          .get(weeklyAvgEngagementUrl)
          .then((response) => {
            this.weeklyAvgEngagement = Math.round(response.data.weekly_average);
          })
          .catch((error) => {
            console.error(
              "Error fetching weekly average engagement data:",
              error
            );
          }),
        axios
          .get(weeklyAvgQuizUrl)
          .then((response) => {
            console.log("response", response);
            const subjectList = this.subjects;
            const weeklyAvgQuiz = response.data.subject_averages;
            console.log("weeklyAvgQuiz", weeklyAvgQuiz);
            this.bar_chart_data = this.mapSubjectData(
              weeklyAvgQuiz,
              subjectList
            );
            console.log("bar_chart_data", this.bar_chart_data);
          })
          .catch((error) => {
            console.error("Error fetching cumulative percentage data:", error);
          }),
      ];
      // Wait for all promises to complete
      Promise.allSettled(apiCalls)
        .then((results) => {
          console.log("All API calls settled:", results);
        })
        .finally(() => {
          // Remove the loader
          this.isLoading = false;
        });
    },
    mapSubjectData(weeklyPercentageData, subjectList) {
  if (!weeklyPercentageData || !subjectList) {
    console.warn("Data is missing for mapping.");
    return [];
  }


  return Object.entries(weeklyPercentageData)
    .map(([subjectName, percentage]) => {
      const matchingSubject = subjectList.find(
        (subject) => subject.subject_name === subjectName
      );

    //   if (matchingSubject) {
        const percentageValue = String(percentage); // Ensure percentage is treated as a string
        return {
          subject: subjectName,
          percentage: Math.round(
            percentageValue.includes("%")
              ? parseFloat(percentageValue.replace("%", ""))
              : parseFloat(percentageValue)
          ), // Handle values without "%"
          color: matchingSubject?.subject_base_color || this.labels.DEFAULT_COLOR.BASE_COLOR, // Use subject_base_color or default
        };
    });
    //   }
    //   // Ignore unmatched subjects
    //   return null;
    // })
    // .filter((data) => data !== null); // Remove null values
}

  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/color.scss";
.date-display {
  color: $text-color;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.01875rem;
text-decoration-line: underline;
text-decoration-style: solid;
text-decoration-skip-ink: none;
text-decoration-thickness: auto;
text-underline-offset: auto;
}
.noRecord {
  color: $text-color;
  font-family: Arial;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem;
  border-radius: 10px;
  background: $dashboard-content-bg-color;
  padding: 30px;
}
.date-selector-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  margin-top: 35px;
  background: $dashboard-content-bg-color;
  border-radius: 10px;
  flex-shrink: 0;
}

.right-arrow-btn {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  margin-right: auto;
  margin-left: 20px;
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
}

.left-arrow-btn {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  margin-right: 25px;
  margin-left: auto;
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
}

.summary-container {
  background: $dashboard-content-bg-color;
  border-radius: 10px;
  flex-shrink: 0;
  color: $text-color;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  padding: 20px 25px !important;
}
.chart-card {
  min-height: 450px;
  flex-shrink: 0;
  border-radius: 10px;
  background: $dashboard-content-bg-color;
  border: none;
  padding: 20px 10px 20px 10px;
}
.noDataFound {
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.weekly-chart-text {
  color: $text-color;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 32.5px */
  letter-spacing: -0.5px;
}
.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $dashboard-content-bg-color;
  padding: 40px 20px 40px 20px;
  border-radius: 10px;
}
.card-1 {
  //   min-width: 308px;
  min-height: 266px;
  flex-shrink: 0;
  border: none;
  border-radius: 10px;
  background: #7dd9cc;
}
.number {
  display: flex;
  justify-content: center;
  color: #000;
  font-size: 90px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 120px */
}
.mins-text {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  display: contents;
}
.number-text {
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
.average-score-container {
  position: relative;
  padding: 45px 30px 30px 60px;
  flex-shrink: 0;
  border-radius: 10px;
  background: $dashboard-content-bg-color;
  display: flex;
  flex-direction: column; /* Arrange bars vertically */
  justify-content: center; /* Center the bars vertically */
  overflow: visible; /* Allow overflow */
}
.progress-bar {
  position: relative; /* Create a positioning context */
  height: 35px; /* Fixed height */
  background-color: #fff; /* Background color */
  border-radius: 40px; /* Rounded corners */
  overflow: visible; /* Allow children to extend outside */
}

.progress {
  height: 100%; /* Full height of the progress bar */
  width: 0; /* Initially set to 0, will be dynamically updated */
  background-color: #7dd9cc; /* Default color */
  border-radius: 40px 0 0 40px; /* Rounded edges only for the left side */
  transition: width 0.5s ease-in-out; /* Smooth transition when width changes */
  position: relative;
}

.percentage {
  display: flex;
  align-items: center;
  padding-left: 15px;
}

.vertical-bar {
  height: 150px;
  position: absolute;
  left: 40px;
  top: 60px;
  bottom: 0;
  width: 0.5px;
  background-color: #323232;
}

.horizontal-line {
  height: 0.5px;
  margin-top: 15px;
  background: #323232;
  margin-right: 15px;
}
.footer-text {
  font-size: 20px;
  color: #000;
  text-align: center;
  font-style: normal;
  font-weight: 400;
}
@media (min-width: 401px) and (max-width: 1024px) {
  .summary-container {
    font-size: 16px;
    line-height: 25px;
  }
  .weekly-chart-text {
    font-size: 15px;
  }
  .chart-card {
    min-height: 320px;
  }
  .noDataFound {
    min-height: 150px;
  }
  .number {
    font-size: 36px;
  }
  .number-text {
    font-size: 13px;
    line-height: 16px;
  }
  .mins-text {
    font-size: 12px;
  }
}
@media screen and (orientation: landscape) {
    @media (max-width: 599px) {
    .card-1 {
      // min-width: 175px;
      min-height: 200px;
    }
  }
  @media (min-width: 600px) and (max-width: 768px) {
    .card-1 {
      // min-width: 175px;
      min-height: 150px;
    }
  }
  @media (min-width: 769px) and (max-width: 960px) {
    .card-1 {
      // min-width: 170px;
      min-height: 135px;
    }
  }
  @media (min-width: 961px) and (max-width: 1024px) {
    .card-1 {
      // min-width: 170px;
      min-height: 160px;
    }
  }
  @media (min-width: 1025px) and (max-width: 1250px) {
    .chart-card {
      min-height: 450px;
    }
  }
  @media (min-width: 1251px) and (max-width: 1440px) {
    .chart-card {
      min-height: 500px;
    }
  }
  @media (min-width: 1441px) and (max-width: 1600px) {
    .chart-card {
      min-height: 540px;
    }
  }
  @media (min-width: 1601px) and (max-width: 1800px) {
    .chart-card {
      min-height: 625px;
    }
  }
}
</style>
