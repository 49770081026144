<template>
  <div class="donut-chart-container">
    <svg ref="chart"></svg>
  </div>
</template>


<script>
import * as d3 from "d3";

export default {
  name: "DonutChart",
  props: {
    chartData: {
      type: Array,
      required: true,
    },
  },
  watch: {
    chartData: {
      immediate: true,
      handler() {
        this.createDonutChart();
      },
    },
  },
  mounted() {
    this.createDonutChart();
    window.addEventListener("resize", this.createDonutChart);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.createDonutChart);
  },
  methods: {
  createDonutChart() {
    const container = this.$refs.chart.parentNode;
    const containerWidth = container.offsetWidth;
    const width = containerWidth * 0.9; // Make chart width proportional to the container
    const height = width * 0.75; // Maintain aspect ratio
    const radius = Math.min(width, height) / 2;

    // Clear previous chart
    d3.select(this.$refs.chart).selectAll("*").remove();

    const svg = d3
      .select(this.$refs.chart)
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", `0 0 ${width} ${height * 1.3}`)
      .attr("preserveAspectRatio", "xMinYMin meet");

    // Create main chart group
    const chartGroup = svg
      .append("g")
      .attr("transform", `translate(${width / 2.5}, ${height / 1.5})`);

    const data = this.chartData.map((item) => item.percentage);
    const colors = this.chartData.map((item) => item.color);
    const labels = this.chartData.map((item) => item.subject);

    const color = d3.scaleOrdinal().range(colors);

    const pie = d3.pie().sort(null);
    const arc = d3.arc().innerRadius(radius * 0.5).outerRadius(radius);

    // Draw the donut chart
    chartGroup
      .selectAll("path")
      .data(pie(data))
      .enter()
      .append("path")
      .attr("d", arc)
      .attr("fill", (d, i) => color(i))
      .append("title")
      .text((d, i) => `${labels[i]}: ${d.data}%`);

    // Add legend
    const isMobile = window.innerWidth <= 960;
    const legendTransformX = isMobile ? width / 2.4 + radius * 1 : width / 2 + radius * 1;
    const legendGroup = svg
      .append("g")
      .attr("transform", `translate(${legendTransformX}, ${height / 2})`);

    const legendItems = legendGroup.selectAll("g").data(labels).enter();

    let currentY = 0;
    legendItems
      .append("g")
      .each(function (d, i) {
        const group = d3.select(this);

        // Add colored rectangles
        group
          .append("rect")
          .attr("width", 15)
          .attr("height", 7)
          .attr("fill", color(i))
          .attr("x", 0)
          .attr("y", currentY);

        // Add text with wrapping
        const fontSize = window.innerWidth <= 960 ? "12px" : "20px"; // Mobile <= 768px, else Desktop
        const text = group
          .append("text")
          .attr("x", 20)
          .attr("y", currentY + 12) // Align text vertically with rectangles
          .style("font-size", fontSize)
          .style("fill", "#000");

        // Split text into multiple lines
        const words = d.split(/\s+/);
        const lineHeight = 14; // Adjusted line height
        const maxWidth = 120; // Maximum width before wrapping
        let line = [];
        let lineNumber = 0;
        let tspan = text
          .text(null)
          .append("tspan")
          .attr("x", 20)
          .attr("y", currentY + 8);

        words.forEach((word) => {
          line.push(word);
          tspan.text(line.join(" "));
          if (tspan.node().getComputedTextLength() > maxWidth) {
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text
              .append("tspan")
              .attr("x", 20)
              .attr("y", currentY + 12 + ++lineNumber * lineHeight)
              .text(word);
          }
        });

        // Adjust vertical position for the next legend item
        currentY += (lineNumber + 1) * lineHeight + 10; // Add padding between legend items
      });
  },
},
};
</script>



<style lang="scss" scoped>

.donut-chart-container {
  width: 100%;
  height: auto;
}

svg {
  display: block;
  margin: auto;
}

/* Media query for mobile devices */
@media (max-width: 1000px) {
  .donut-chart-container {
    width: 100%;
    height: auto;
    padding: 10px; /* Optional: Add padding to avoid touching the screen edges */
  }

  svg {
    width: 270px;  /* Set smaller width for mobile */
    height: 200px; /* Set smaller height for mobile */
    margin: 0;
  }

  .donut-chart-container text {
    font-size: 10px; /* Adjust text size on smaller screens */
  }
}
</style>
