// labels.js
export const LABELS = {
    FINISH_SETUP: {
        FINISH_SETUP_TEXT: "is ready to start learning.",
        FINISH_SETUP_DESCRIPTION: "To achieve an optimum experience for your child. Guide them on how to use the app for the first time.",
        FINISH_SETUP_BUTTON: "Start your 14 day trial",
        FINISH_SETUP_PAYMENT_TEXT: "$14.99 per month after 14 day trial."
    },
    DEFAULT_COLOR: {
        BASE_COLOR: "#7DD9CC",
        COLOR_1: "#9EF9EC",
        COLOR_2: "#E1FFFB"
    }
  };
  