<template>
  <div>
      <div class="row m-0 margin-top margin-bottom">
          <div>Current subject selections </div>
          <div class="ml-auto">Change selection</div>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

</style>
