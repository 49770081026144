<template>
  <div class="container-fluid finish-setup-outer-container">
    <div class="row m-0 container-min-height finish-setup-padding">
      <div
        class="col-12 col-sm-12 col-md-12 col-xl-12 bg-white finish-setup-inner-container"
      >
        <div class="row" style="display: grid">
          <h1 class="title">Forgot passcode</h1>
          <p class="subtitle">
            Email has been sent to the email address on file. If you don’t
            receive it in a couple of minutes, 
            <a
              class="click-here"
              :class="{ disabled: isDisabled }"
              @click="handleClick"
            >
              click here
            </a>
            to resend it.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ForgotPasscodeComponent",
  data() {
    return {
      passcode: ["", "", "", ""], // Array to hold each input value
      errorMessage: "", // Error message for invalid passcode
      isDisabled: true, // Controls whether "click here" is disabled
    };
  },
  mounted() {
    // Start initial countdown for 10 seconds
    this.startTimer(10);
  },
  computed: {
    // Check if all 4 digits are filled
    isPasscodeComplete() {
      return this.passcode.every((digit) => digit.length === 1);
    },
  },
  methods: {
      sanitizeInput(index) {
      this.errorMessage = "";
      // Ensure the input is numeric and only one character
      this.passcode[index] = this.passcode[index].replace(/[^0-9]/g, "").slice(0, 1);

      // Automatically move focus to next input when the current one is filled
      if (this.passcode[index].length === 1 && index < this.passcode.length - 1) {
        this.focusNextInput(index);
      }
    },
    moveFocus(index, event) {
      if (event.key === "Backspace" && index > 0 && !this.passcode[index]) {
        // If Backspace is pressed, move focus to the previous input
        this.focusPreviousInput(index);
      }
    },

    focusNextInput(index) {
      // Focus on the next input field after current one
      if (index < this.passcode.length - 1) {
        this.$nextTick(() => {
          this.$refs[`passcodeInput${index + 1}`][0].focus();
        });
      }
    },

    focusPreviousInput(index) {
      // Focus on the previous input field
      if (index > 0) {
        this.$nextTick(() => {
          this.$refs[`passcodeInput${index - 1}`][0].focus();
        });
      }
    },
    startTimer(seconds) {
      this.isDisabled = true; // Disable the "click here" link
      const interval = setInterval(() => {
        if (seconds > 0) {
          seconds--;
        } else {
          this.isDisabled = false; // Enable the "click here" link
          clearInterval(interval); // Stop the timer
        }
      }, 1000);
    },
    handleClick() {
      if (!this.isDisabled) {
        console.log("Resend clicked."); // Debugging statement for click
        this.resendEmail(); // Trigger the resendEmail API call
      } else {
        console.log("Click disabled."); // Debugging for disabled state
      }
    },
    async resendEmail() {
       // Disable the "click here" link and prevent further clicks
      try {
        const userEmail = localStorage.getItem("email");
        const payload = {
          user_email: userEmail,
        };

        // Call the API to send the password reset email
        const response = await axios.post(
          process.env.VUE_APP_FLUTE_N_FEATHER_DEV_BASE_API_URL_DOMAIN +
            process.env.VUE_APP_FLUTE_N_FEATHER_REQUEST_RESET,
          payload
        );

        if (
          response.data.message === "Password reset email sent successfully"
        ) {
          console.log("email sent successfully");
          // Start the 30-second countdown after a successful API response
          this.startTimer(30);
        } else {
          console.log("Something went wrong.");
          this.isDisabled = false; // Re-enable the link if the API fails
        }
      } catch (error) {
        console.log("Something went wrong.");
        this.isDisabled = false; // Re-enable the link if the API fails
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/color.scss";
.finish-setup-outer-container {
 background-color: #F8F2DC;
}
.finish-setup-padding {
  padding: 30px;
}
.finish-setup-inner-container {
  border-radius: 10px !important;
  padding: 30px;
  display: grid;
  align-items: center;
  height: 88vh;
}
.finish-setup-container {
  min-height: 330px;
  border-radius: 10px;
  background: var(
    --intro-gradient-1,
    linear-gradient(0deg, #fff8e2 0.71%, #fff1c3 56.33%, #ffeaa6 99.97%)
  );
}
.passcode-setup {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f1e6b9; /* Light beige background */
}

.disabled {
  color: #5e6366; /* Light gray for disabled text */
  cursor: not-allowed; /* Change cursor to indicate disabled state */
  text-decoration: line-through; /* Strike-through effect */
  pointer-events: none; /* Prevent interaction */
  opacity: 0.6; /* Slight transparency for a faded look */
}


.text-danger{
    text-align: center;
}

.click-here{
    color: #383838;

/* BODY - DESKTOP */
font-family: Arial;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 32px;
text-decoration-line: underline;
text-decoration-style: solid;
text-decoration-skip-ink: none;
text-decoration-thickness: auto;
text-underline-offset: auto;
text-underline-position: from-font;
}

.container {
  text-align: center;
  max-width: 1300px;
  height: 500px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
}

/* Ultra-Wide Screens (above 1920px) */
@media (min-width: 1921px) {
  .container {
    max-width: 94%;
    height: 704px;
    margin-left: 58px;
    margin-top: 29px;
    border-radius: 8px;
  }
}

/* Large Desktops (1441px to 1920px) */
@media (min-width: 1601px) and (max-width: 1920px) {
  .container {
    max-width: 89%;
    border-radius: 8px;
    height: 738px;
    margin-left: 100px;
    margin-top: 7px;
  }
}

/* Large Desktops (1441px to 1920px) */
@media (min-width: 1441px) and (max-width: 1600px) {
  .container {
    max-width: 92%;
    border-radius: 8px;
    height: 711px;
    margin-left: 60px;
    margin-top: -8px;
  }
}

/* Standard Desktops (1025px to 1440px) */
@media (min-width: 1200px) and (max-width: 1440px) {
  .container {
    max-width: 93%;
    height: 504px;
    margin-left: 47px;
    border-radius: 8px;
    margin-top: 8px;
  }
}
/* Standard Desktops (1025px to 1440px) */
@media (min-width: 1025px) and (max-width: 1200px) {
  .container {
    max-width: 93%;
    height: 514px;
    margin-left: 40px;
    margin-top: 8px;
  }
}

/* Large Tablets (768px to 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
  .container {
    max-width: 98%;
    height: 300px;
    margin-left: 10px;
    border-radius: 8px;
    margin-top: 10px;
  }
}

/* Medium Tablets (576px to 767px) */
@media (min-width: 576px) and (max-width: 767px) {
  .container {
    max-width: 99%;
    height: 314px;
    border-radius: 8px;
    margin-left: 5px;
    margin-top: -4px;
  }
}

/* Large Mobiles (480px to 575px) */
@media (min-width: 480px) and (max-width: 575px) {
  .container {
    max-width: 100%;
    height: 343px;
    margin-left: -2px;
    border-radius: 8px;
    margin-top: 0px;
  }
}

/* Small Mobiles (less than 480px) */
@media (max-width: 480px) {
  .container {
    width: 100%;
    height: 330px;
    border-radius: 8px;
    margin-left: 2px;
    margin-top: 3px;
  }
}

/* Ultra-Small Devices (height less than 300px) */
@media (max-height: 300px) {
  .container {
    width: 100%;
    border-radius: 8px;
    height: 200px;
    margin-left: 20px;
    margin-top: -20px;
  }
}

.title {
  margin-bottom: 12px;
  color: #383838;
  font-family: Arial;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.forgot-passcode-container {
text-align: center;
margin-top: -85px;
margin-left: 430px;
}


.forgot-passcode{
text-align: center;
color: #000;
font-family: Arial;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-decoration-line: underline;
text-decoration-style: solid;
text-decoration-skip-ink: none;
text-decoration-thickness: auto;
text-underline-offset: auto;
text-underline-position: from-font;
opacity: 0.5;
}

.subtitle {
color: #383838;
text-align: center;

/* BODY - DESKTOP */
font-family: Arial;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 32px; /* 160% */
}

/* Extra Large Screens (1441px - 1600px) */
@media (min-width: 1441px) and (max-width: 1600px) {
  .subtitle {
    font-size: 20px;
    width: 600px;
    margin-left: auto; /* Center alignment */
    margin-right: auto;
  }
}

/* Ultra Large Screens (1601px - 1920px) */
@media (min-width: 1601px) and (max-width: 1920px) {
  .subtitle {
    font-size: 20px;
    width: 630px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* 4K and Above (1921px and up) */
@media (min-width: 1921px) {
  .subtitle {
    font-size: 20px;
    width: 628px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* Tablets (768px - 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
  .subtitle {
    font-size: 17px;
    width: 72%;
    margin-left: auto;
    margin-right: auto;
  }
}

/* Small Laptops (1200px - 1440px) */
@media (min-width: 1200px) and (max-width: 1440px) {
  .subtitle {
    font-size: 20px;
    width: 601px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* Medium Laptops (1025px - 1200px) */
@media (min-width: 1025px) and (max-width: 1200px) {
  .subtitle {
    font-size: 20px;
    width: 603px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* Small Devices (576px - 767px) */
@media (min-width: 576px) and (max-width: 767px) {
  .subtitle {
    font-size: 17px;
    width: 86%;
    margin-left: auto;
    margin-right: auto;
  }
}

/* Extra Small Devices (480px - 575px) */
@media (min-width: 480px) and (max-width: 575px) {
  .subtitle {
    font-size: 17px;
    width: 79%;
    margin-left: auto;
    margin-right: auto;
  }
}

/* Very Small Devices (Below 480px) */
@media (max-width: 480px) {
  .subtitle {
    font-size: 17px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
}
.input-group {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 30px;
}

.passcode-input {
  width: 64px;
  height: 75px;
  flex-shrink: 0;
  margin-top: 20px;
  border-radius: 10px;
  font-size: 32px; /* Large font size for prominent display */
  text-align: center; /* Center the number */
  border: 2px solid #f8f2dc; /* Match border style from the image */
  background: #fbf8ec;
  outline: none; /* Remove focus outline */
  font-family: Arial, sans-serif; /* Font style */
  color: #383838; /* Dark text color */
}

.passcode-input:focus {
  border-color: #b7a684; /* Focus border color */
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.save-passcode {
  width: 310px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #ede7d2;
  border: none;
  cursor: pointer;
  color: #323232;
  font-family: Arial;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.3px;
}

/* Disabled state */
.save-passcode:disabled {
  background: #5e6366;
  color: white;
  cursor: not-allowed;
  pointer-events: none; /* Ensure it is not clickable */
  opacity: 0.6; /* Make it visually inactive */
}

/* Responsive Design: Small screens (mobile phones) */
@media (max-width: 768px) {


  .passcode-input {
    width: 50px;
    height: 50px;
    font-size: 1.2rem;
  }

  .save-passcode {
    width: 251px;
    height: 42px;
    font-size: 14px;
  }
}

/* Responsive Design: Medium screens (tablets) */
@media (min-width: 769px) and (max-width: 1024px) {


  .passcode-input {
    width: 55px;
    height: 55px;
  }

  .save-passcode {
    width: 251px;
    height: 42px;
    font-size: 14px;
  }
}

/* Responsive Design: Large screens (laptops and desktops) */
@media (min-width: 1025px) {


  .passcode-input {
    width: 64px;
    height: 75px;
  }

  .save-passcode {
    font-size: 1.1rem;
  }
}
</style>
