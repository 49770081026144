<template>
  <div class="container-fluid container-min-height d-flex justify-content-center align-items-center">
    <orientation-detector
      @update-orientation="updateOrientation"
      @pause-question-audio="pauseAudio"
      @resume-question-audio="playAudio"
    />
    <audio
      id="audio-player"
      :src="audioSrc"
      preload="auto"
      type="audio/mpeg"
      @ended="audioEnded"
    ></audio>
    <div class="row m-0 fun-page">
      <div class="col-12 col-sm-12 col-md-12 col-xl-12 p-0">
        <div class="layout">
          <div class="col-12 p-0 vertical-tabs">
            <div class="tab-item">
              <button
                class="tab-button tab-top-button no-hover"
                :style="{
                  backgroundColor:'#EDE6CF',
                  color: '#323232',
                }"
              >
                <img src="../assets/Fun_Qus.svg" alt="" />
                <span>Ask me anything</span>
              </button>
            </div>
            <div class="tab-item home-tab">
              <button
                class="tab-button"
                @mouseover="hoveredTab = 'home'"
                @mouseleave="hoveredTab = null"
                @click="topicDetailsBack"
                :style="{
                  backgroundColor:'#EDE6CF',
                  color: '#323232',
                }"
              >
                <img src="../assets/Home_1.svg" alt="" />
                <span>Home</span>
              </button>
            </div>
          </div>
          <!-- Fun section -->
          <div class="col-12 p-0 content qus-quiz-container">
            <div class="h-100">
              <div
                class="h-100"
                :class="{
                  'question-container': !isLoading,
                }"
              >
                <!-- Show loading image while waiting for API response after microphone click -->
                <div v-if="isLoading" class="h-100">
                  <div class="h-100">
                    <image-loader-component
                      :isLoading="isLoading"
                      :imageResponse="loadingStateImgResponse"
                      :audioResponse="audioResponse"
                    />
                  </div>
                </div>
                <!-- Show Question body after LLM response -->
                <div
                  v-else
                  class="col-12 p-0 qustion-body"
                  :class="{
                    'blur-content': listeningText === 'I\'m listening',
                  }"
                >
                  <div class="row m-0">
                    <p class="dummy-text" v-if="!scrollableText">
                      Do you want to hear a joke or learn about something
                      specific.<br />
                      Let me know and I will dig up an answer for you.
                    </p>
                    <div ref="scrollContainer" class="text-scroller-container">
                      <div class="dummy-text">{{ scrollableText }}</div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-12 question-controls"
                  ref="featheranimationContainer"
                >
                  <div
                    v-if="!isLoading"
                    class="character-animation feather-animation fnff-peacock-left"
                    ref="featherImage"
                  ></div>
                  <!-- Show microphone image Before LLM response screens -->
                  <div v-if="!isLoading && !audioLoaded" class="tooltip-container">
                    <img
                      v-if="isRecording && !afterIsRecording"
                      class="question-play-n-pause cursor-pointer"
                      src="../assets/Fnff-Microphone-Listen.png"
                    />
                    <img
                      v-else
                      class="question-play-n-pause cursor-pointer"
                      src="../assets/Fnff-Microphone-Mute.png"
                      @click="toggleRecording"
                    />
                    <span class="tooltiptext">{{ listeningText }}</span>
                  </div>
                  <!-- Show audio controls like stop, play and pause button After LLM response screens -->
                  <div class="row stopButton" v-if="audioLoaded">
                    <button
                      type="button"
                      @click="stopAudio"
                      class="control-stop-button mr-2"
                      ref="featherStopAnimationContainer"
                    >
                      <img
                        class="cursor-pointer control-image"
                        src="../assets/Fnff-Stop-Button.png"
                      />
                      <span class="ml-2">Stop</span>
                    </button>
                    <button
                      class="control-button"
                      @click="pauseAudio"
                      v-if="!isPaused && audioLoaded"
                      ref="featherStopAnimationContainer"
                    >
                      <img
                        class="control-image cursor-pointer"
                        src="../assets/Fnff-Qus-Pause-Button.png"
                      />
                      <span class="ml-2">Pause</span>
                    </button>
                    <button v-else class="control-button" @click="playAudio">
                      <img
                        class="control-image cursor-pointer"
                        src="../assets/Fnff-Qus-Play-Button.png"
                      />
                      <span class="ml-2">Play</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!isMicrophoneAllowed && isMicrophoneBrowserAllowed"
      class="auth-overlay"
    >
      <div>
        <div class="prompt-block">
          <template>
            <div>
              <h4>We need access to your microphone.</h4>
              <p>
                Please give access to your michrophone and refresh the page.
              </p>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ImageLoaderComponent from "@/components/common/ImageLoaderComponent.vue";
import { updateTitle } from "@/utils/utils";
import NoSleep from "@uriopass/nosleep.js";
import OrientationDetector from "@/components/common/OrientationDetector.vue";
export default {
  components: { OrientationDetector, ImageLoaderComponent },
  name: "FunTopicComponent",
  data() {
    return {
      isRecording: false,
      afterIsRecording: false,
      mediaStream: null,
      mediaRecorder: null,
      silenceTimer: null,
      isMicrophoneAllowed: false,
      isMicrophoneBrowserAllowed: false,
      recordedChunks: [],
      isLoading: false,
      userName: localStorage.getItem("username"),
      userEmail: localStorage.getItem("email"),
      subjectName: localStorage.getItem("subjectName"),
      sound: null,
      audioLink: null,
      audioLoaded: false,
      audioFile: null,
      listeningText: "Click the microphone to speak",
      landingText:
        "Do you want to hear a joke or learn about something specific. Let me know and I will dig up an answer for you.",
      isPaused: false,
      //loader Images
      backgroundSrc: require("@/assets/Vector.png"),
      imageFiles: [require("@/assets/StaticPeacock.png")],
      imageResponse: [],
      loadingStateImg: [require("@/assets/WaitingLLMResponse.gif")],
      loadingStateImgResponse: [],
      backgroundImage: "",
      audioFiles: [
        require("@/assets/jenny_response1_out.mp3"),
        require("@/assets/jenny_response2_out.mp3"),
        require("@/assets/jenny_response3_out.mp3"),
        require("@/assets/jenny_response4_out.mp3"),
        require("@/assets/jenny_response5_out.mp3"),
      ],
      audioResponse: [],
      // After LLM response Images
      LLMResponseImg: [require("@/assets/WaitingLLMResponse.gif")],
      LLMimageResponse: [],
      audioSrc: "",
      audio_player: null,
      noSleep: null,
      isPortrait: false,
      scrollableText: "",
      wordChunkSize: 20, // Number of words per scroll chunk
      interval: null, // Interval ID for scrolling
      scrollAmount: 0, // Amount of scroll in pixels for each chunk
      scrollInterval: null,
      isQustionAndAnswerPaused: false,
      characterFeather: null,
      animationInterval: null,
      scaleFactor: 0.3,
      videoData: {
        fluteImage: null,
        featherImage: null,
        animation_checkpoints: [],
      },
      videoPlayer: null,
      currentSprites: {
        flute: "",
        feather: "",
      },
      spriteData: {
        flute: {},
        feather: {},
      },
      images: {
        flute: new Image(),
        feather: new Image(),
      },
      poseChanges: [],
    };
  },
  async mounted() {
    await this.fetchSpriteCharacter(); // Load the video URL initially
    this.characterFeather = JSON.parse(localStorage.getItem("characterData"));
    this.loadQuestionSpriteData("Feather", "Listening");
    updateTitle("Fun");
    this.noSleep = new NoSleep(); // Initialize NoSleep
    this.checkMicrophoneAccess();
    window.addEventListener("popstate", this.handlePopState);
    // loader image
    this.imageFiles.map((imageFile) => {
      return axios
        .get(imageFile, { responseType: "blob" })
        .then((response) => {
          const imageUrl = URL.createObjectURL(response.data);
          if (imageUrl) {
            this.imageResponse.push(imageUrl);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    });
    // loader background image
    axios
      .get(this.backgroundSrc, { responseType: "blob" })
      .then((response) => {
        const imageUrl = URL.createObjectURL(response.data);
        this.backgroundImage = imageUrl;
      })
      .catch((error) => {
        console.error(error);
      });
    // loader background audio
    this.audioFiles.map((audioFile) => {
      return axios
        .get(audioFile, { responseType: "blob" })
        .then((response) => {
          const reader = new FileReader();
          reader.readAsDataURL(response.data);
          reader.onload = () => {
            const audioUrl = reader.result;
            if (audioUrl) {
              this.audioResponse.push(audioUrl);
            }
          };
        })
        .catch((error) => {
          console.error(error);
        });
    });
    this.loadingStateImg.map((imageFile) => {
      return axios
        .get(imageFile, { responseType: "blob" })
        .then((response) => {
          const imageUrl = URL.createObjectURL(response.data);
          if (imageUrl) {
            this.loadingStateImgResponse.push(imageUrl);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    });
    // After LLM response image
    this.LLMResponseImg.map((imageFile) => {
      return axios
        .get(imageFile, { responseType: "blob" })
        .then((response) => {
          const imageUrl = URL.createObjectURL(response.data);
          if (imageUrl) {
            this.LLMimageResponse.push(imageUrl);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    });
    setTimeout(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, 100);  
  },
  watch: {
    // audioSrc(newSrc) {
    //   if (newSrc) {
    //     this.$nextTick(() => {
    //       const audioElement = document.getElementById("audio-player");
    //       if (audioElement) {
    //         audioElement.addEventListener("ended", this.audioEnded);
    //         this.playAudio();
    //       }
    //     });
    //   }
    // },
  },
  methods: {
    async fetchSpriteCharacter() {
      const dataTosend = {
        character_name: "Feather",
      };

      try {
        const response = await axios.post(
          process.env.VUE_APP_FLUTE_N_FEATHER_DEV_BASE_API_URL_DOMAIN +
            process.env.VUE_APP_FLUTE_N_FEATHER_CHARACTER_ACTION,
          dataTosend
        );

        if (response && response.data) {
          localStorage.setItem("characterData", JSON.stringify(response.data));
        } else {
          this.$notify({
            group: "foo",
            type: "error",
            text:
              response?.data?.message ||
              "Something went wrong. Try again later.",
          });
        }
      } catch (error) {
        console.error("Something went wrong. Try again later.", error);
      }
    },
    async checkMicrophoneAccess() {
      try {
        const constraints = { audio: true };
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        this.mediaRecorder = new MediaRecorder(stream);
        this.mediaStream = stream;
        this.isMicrophoneAllowed = true;
      } catch (error) {
        this.isMicrophoneAllowed = false;
        this.isMicrophoneBrowserAllowed = true;
      }
    },
    stopScrolling() {
      if (this.scrollInterval) {
        clearInterval(this.scrollInterval); // Stop the interval-based scrolling
        this.scrollInterval = null;
      }
    },
    resetScrolling() {
      if (this.scrollInterval) {
        clearInterval(this.scrollInterval); // Clear any existing scroll interval
        this.scrollInterval = null;
      }
    },
    toggleRecording() {
      clearTimeout(this.silenceTimer);
      if (!this.isRecording && !this.afterIsRecording) {
        this.startRecording();
        if (this.noSleep) {
          this.noSleep.enable();
        }
      } else if (this.afterIsRecording) {
        this.stopRecording();
        if (this.noSleep) {
          this.noSleep.enable();
        }
        this.afterIsRecording = false;
      }
    },
    async startRecording() {
      try {
        // event.preventDefault();
        // if (!this.isRecording && this.isMicrophoneAllowed) {
        this.isRecording = true;
        this.listeningText = "I'm listening";
        this.loadQuestionSpriteData("Feather", "Listening");
        let silenceStart;

        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((stream) => {
            this.mediaRecorder = new MediaRecorder(stream);
            this.chunks = [];

            this.mediaRecorder.start();

            const audioContext = new AudioContext();
            const source = audioContext.createMediaStreamSource(stream);
            const processor = audioContext.createScriptProcessor(512, 1, 1);

            source.connect(processor);
            processor.connect(audioContext.destination);

            // Enable the microphone button after 5 seconds
            setTimeout(() => {
              this.afterIsRecording = true;
              this.listeningText = "Tap below for a response";
              silenceStart = Date.now();
            }, 5000);

            processor.onaudioprocess = (event) => {
              if (
                this.mediaRecorder &&
                this.mediaRecorder.state !== "recording"
              )
                return;

              if (this.afterIsRecording === true) {
                const input = event.inputBuffer.getChannelData(0);
                let isSilent = input.every((value) => Math.abs(value) < 0.01);

                if (isSilent) {
                  if (Date.now() - silenceStart > 2000) {
                    this.mediaRecorder && this.mediaRecorder.stop();
                    stream.getTracks().forEach((track) => track.stop());
                    processor.disconnect();
                    audioContext.close();
                  }
                } else {
                  silenceStart = Date.now();
                }
              }
            };

            this.mediaRecorder.ondataavailable = (event) => {
              if (event.data.size > 0) {
                this.chunks.push(event.data);
              }
            };

            this.mediaRecorder.onstop = () => {
              const audioBlob = new Blob(this.chunks, {
                type: "audio/wav; codecs=0",
              });

              const now = new Date();
              const timestamp =
                now.getFullYear().toString() +
                (now.getMonth() + 1).toString().padStart(2, "0") +
                now.getDate().toString().padStart(2, "0") +
                "_" +
                now.getHours().toString().padStart(2, "0") +
                now.getMinutes().toString().padStart(2, "0") +
                now.getSeconds().toString().padStart(2, "0");

              const filename = "audio_" + timestamp + ".wav";
              const audioFile = new File([audioBlob], filename, {
                type: "audio/wav",
              });
              // this.sendRecordingToApi(audioFile);
              this.audioFile = audioFile;
              this.sendRecordingToApi(audioFile);
            };
          })
          .catch((error) => console.log(error));
        // }
      } catch (error) {
        console.error("Error sending audio to API:", error);
      }
    },

    stopRecording() {
      if (this.isRecording) {
        this.isRecording = false;
        if (this.mediaRecorder && this.mediaRecorder.state === "recording") {
          // Stop the mediaRecorder
          this.mediaRecorder.stop();
        }
      }
    },

    // Handle the orientation update from the child component
    updateOrientation(isPortrait) {
      this.isPortrait = isPortrait;
      const audioElement = document.getElementById("audio-player");
      if (audioElement.getAttribute("src")) {
        if (!this.isPortrait && !this.isPaused) {
          // If the device is in landscape mode, play the audio
          audioElement.play();
          this.autoScrollText();
        } else {
          // If the device is in portrait mode, pause the audio
          audioElement.pause();
          this.stopScrolling();
        }
      }
    },

    sendRecordingToApi(audioFile) {
      this.characterFeather = JSON.parse(localStorage.getItem("characterData"));
      this.isLoading = true;
      this.audioLoaded = false;
      this.listeningText = "Click the microphone to speak";
      this.$store.dispatch("updateFunToipcLodingState", "");
      this.$store.dispatch("updateIsAudioResponse", "");

      const formData = new FormData();
      formData.append("audioFile", audioFile);
      formData.append("user_email", this.userEmail);
      formData.append("topic", "have fun");
      formData.append("session_state", null);

      axios
        .post(
          process.env.VUE_APP_FLUTE_N_FEATHER_DEV_BASE_API_URL_DOMAIN +
            process.env.VUE_APP_FLUTE_N_FEATHER_TRANSCRIBE_DOMAIN_URL_PATH,
          formData
        )
        .then((response) => {
          if (response && response.data && response.data.audio_url) {
            this.audioSrc = response.data.audio_url;
            this.scrollableText = response.data.llm_response;
            //this.optionText = this.formatLLMResponse(this.scrollableText);
            // Ensure DOM is updated before accessing the audio element
            this.$nextTick(() => {
              this.audio_player = document.getElementById("audio-player");
              if (this.audio_player) {
                this.audio_player.src = this.audioSrc;
                this.audio_player.volume = 1.0;

                this.$store.dispatch("updateFunToipcLodingState", "");
                this.$store.dispatch("updateIsAudioResponse", true);
                this.isLoading = false;
                this.audioLoaded = true;
                if (this.noSleep) {
                  this.noSleep.enable();
                }
                // Only play the audio if the device is already in landscape mode
                if (!this.isPortrait) {
                  this.audio_player.play();
                  this.loadQuestionSpriteData("Feather", "Speaking");
                } else {
                  this.audio_player.pause();
                }
                this.$nextTick(() => {
                  const container = document.querySelector(
                    ".text-scroller-container"
                  );
                  console.log("container", container);
                  console.log('scrollableText', this.scrollableText);
                  if (container) {
                    // Reset scroll amount to the top for each new response
                    container.scrollTo({
                      top: 0, // Reset to the top
                      behavior: "auto", // No smooth behavior when resetting
                    });
                    this.scrollAmount = 0; // Reset scrollAmount to 0

                    // Ensure any existing scroll interval is cleared
                    clearInterval(this.interval);
                    // Split the formatted text into chunks and start scrolling for Question
                    if (this.scrollableText) {
                      if (!this.isPortrait) {
                        this.autoScrollText(); // Start auto-scroll for Question
                      }
                    }
                  }
                });
              } else {
                console.error("Audio element not found");
                this.$store.dispatch("updateFunToipcLodingState", "");
                this.isLoading = false;
                this.audioLoaded = false;
              }
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          this.listeningText = "Click the microphone to speak";
          this.isRecording = false;
          this.afterIsRecording = false;
          this.audioLoaded = false;
          this.isLoading = false;
          this.$notify({
            group: "foo",
            type: "error",
            text:
              "Something went wrong. Please try again." ||
              error.response.data.message,
          });
        });
    },
    autoScrollText() {
      const container = this.$refs.scrollContainer;

      if (!container || !(container instanceof Element)) {
        console.error("Invalid container element for scrolling.");
        return;
      }

      const scrollDelay = 6000; // Delay between scrolls (in ms)
      const scrollDuration = 1000; // Time in ms for one scroll transition
      const initialDelay = 10000; // Initial delay for the first line (in ms)
      const lineHeight = parseFloat(getComputedStyle(container).lineHeight);

      let startTime = null; // Initialize start tim

      // Function to smoothly scroll
      const smoothScroll = (timestamp) => {
        // If the audio is paused, stop scrolling
        const audioElement = document.getElementById("audio-player");
        if (audioElement && audioElement.paused) {
          return; // Exit if audio is paused
        }

        if (!startTime) startTime = timestamp; // Set the start time when function is first called
        const elapsed = timestamp - startTime; // Calculate time elapsed

        const progress = Math.min(elapsed / scrollDuration, 1); // Ensure progress doesn't exceed 1
        const scrollAmount = this.scrollAmount + progress * lineHeight; // Calculate the scroll amount

        container.scrollTop = scrollAmount; // Set the scrollTop position

        // Continue scrolling if progress < 1
        if (progress < 1) {
          this.scrollAnimationFrame = requestAnimationFrame(smoothScroll); // Continue smooth scrolling
        } else {
          this.scrollAmount += lineHeight; // Update scroll amount after one complete line is scrolled

          // Stop if the bottom is reached
          if (
            this.scrollAmount >=
            container.scrollHeight - container.clientHeight
          ) {
            clearInterval(this.scrollInterval); // Stop scrolling at the bottom
          } else {
            // Pause for the scrollDelay before starting the next scroll
            setTimeout(() => {
              startTime = null; // Reset start time for the next scroll
              this.scrollAnimationFrame = requestAnimationFrame(smoothScroll); // Start next smooth scroll
            }, scrollDelay);
          }
        }
      };
      // Start with an initial delay to allow users to read the first line
      setTimeout(() => {
        this.scrollAmount = 0; // Ensure the scroll amount starts from the top
        this.scrollAnimationFrame = requestAnimationFrame(smoothScroll); // Start the smooth scrolling
      }, initialDelay);
    },
    audioEnded() {
      this.resetScrolling();
      this.scrollableText = "";
      this.isRecording = false;
      this.audioLoaded = false;
      this.afterIsRecording = false;
      this.audioLink = null;
      this.audioSrc = "";
      this.audio_player = null;
      this.loadQuestionSpriteData("Feather", "Listening");
      if (this.noSleep) {
        this.noSleep.disable();
      }
    },
    topicDetailsBack() {
      this.audioSrc = "";
      this.audio_player = null;
      this.$router.push("/home");
    },
    playAudio() {
      this.$nextTick(() => {
        const audioElement = document.getElementById("audio-player");
        if (audioElement) {
          this.loadQuestionSpriteData("Feather", "Speaking");
          audioElement.play();
          audioElement.volume = 1.0;
          this.isPaused = false;
          this.isQustionAndAnswerPaused = false;
          this.scrollAmount = this.$refs.scrollContainer.scrollTo;
          clearInterval(this.interval);
          this.autoScrollText();
          if (this.noSleep) {
            this.noSleep.enable();
          }
        } else {
          console.error("Audio element not found");
        }
      });
    },

    pauseAudio() {
      const audioElement = document.getElementById("audio-player");
      if (audioElement) {
        if (this.noSleep) {
          this.noSleep.disable();
        }
        audioElement.pause();
        clearInterval(this.animationInterval);
        this.isPaused = true;
        // Stop scrolling
        this.isQustionAndAnswerPaused = true;
        clearInterval(this.scrollInterval);
      } else {
        console.error("Audio element not found");
      }
    },
    stopAudio() {
      const audioElement = document.getElementById("audio-player");
      if (audioElement) {
        audioElement.pause();
        audioElement.currentTime = 0;
        this.isPaused = false;
        this.isRecording = true;
        this.audioLoaded = false;
        this.scrollableText = "";
        this.afterIsRecording = false;
        this.audioLink = null;
        this.audioSrc = "";
        this.audio_player = null;
        this.listeningText = "I'm listening";
        if (this.noSleep) {
          this.noSleep.disable();
        }
        this.startRecording();
        this.resetScrolling();
        this.isQustionAndAnswerPaused = false;
      } else {
        console.error("Audio element not found");
      }
    },
    // Load sprite JSON and image data for a given character and pose
    async loadQuestionSpriteData(character, state) {
      try {
        // Get the correct JSON URL based on the character's state (Listening or Speaking)
        const spriteMetadataUrl =
          this.characterFeather.action_sprite_metadata_map[character][state];
        if (!spriteMetadataUrl) {
          throw new Error(`Metadata URL not found for state: ${state}`);
        }
        // Fetch the sprite data JSON
        const spriteData = await this.fetchPoseJson(spriteMetadataUrl);

        // Update the sprite with the loaded image URL
        const imageUrl =
          this.characterFeather.action_sprite_map[character][state]; // Get the image URL for the given state
        this.currentSprites[character] = imageUrl;

        const image = new Image();
        image.src = imageUrl;

        // Wait for the image to load before starting the animation
        await new Promise((resolve, reject) => {
          image.onload = resolve;
          image.onerror = reject;
        });

        // Start the animation with the loaded sprite data and image
        this.startAnimation(character, spriteData, image, state);
      } catch (error) {
        console.error("Error loading sprite data:", error);
      }
    },
    async fetchPoseJson(url) {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to fetch JSON: ${response.statusText}`);
        }
        return await response.json();
      } catch (error) {
        console.error("Error fetching JSON:", error);
        return null;
      }
    },
    startAnimation(character, spriteData, image, state) {
      const frames = Object.keys(spriteData.frames);
      const element =
        character.toLowerCase() === "feather"
          ? this.$refs.featherImage
          : this.$refs.fluteImage;

      // Clear any existing animation interval if it exists
      if (this.animationInterval) clearInterval(this.animationInterval);
      // Run the animation loop with dynamic timing based on the state
      this.runAnimationLoop(frames, spriteData, image, element, state);
    },

    runAnimationLoop(frames, spriteData, image, element, state) {
      let frameIndex = 0;
      const frameCount = frames.length;
      // Set dynamic frame duration based on state (faster for speaking, slower for listening)
      const baseDuration = state === "Speaking" ? 30 : 50; // Adjust values as needed

      // Main loop for frame animation with responsive scaling
      this.animationInterval = setInterval(() => {
        const frameKey = frames[frameIndex];
        const frameData = spriteData.frames[frameKey].frame;

        const container = this.$refs.featheranimationContainer;
        const stopContainer = this.$refs.featherStopAnimationContainer;
        if (container) {
          const containerWidth = container.offsetWidth;
          const containerHeight = "115";
          const baseWidth = frameData.w || 513;
          const baseHeight = frameData.h || 411;
          this.scaleFactor =
            window.innerWidth <= 956
              ? Math.min(
                  containerWidth / baseWidth,
                  containerHeight / baseHeight
                )
              : 0.5; // Default scale for larger screens
        }
        if (stopContainer) {
          const containerWidth = container.offsetWidth;
          const containerHeight = "115";
          const baseWidth = frameData.w || 513;
          const baseHeight = frameData.h || 411;
          this.scaleFactor =
            window.innerWidth <= 956
              ? Math.min(
                  containerWidth / baseWidth,
                  containerHeight / baseHeight
                )
              : 0.5; // Default scale for larger screens
        }
        // Update element's background and size for the current frame
        element.style.backgroundImage = `url(${image.src})`;
        element.style.backgroundPosition = `-${
          frameData.x * this.scaleFactor
        }px -${frameData.y * this.scaleFactor}px`;
        element.style.width = `${frameData.w * this.scaleFactor}px`;
        element.style.height = `${frameData.h * this.scaleFactor}px`;
        element.style.backgroundSize = `${image.width * this.scaleFactor}px ${
          image.height * this.scaleFactor
        }px`;

        // Cycle through frames
        frameIndex = (frameIndex + 1) % frameCount;
      }, baseDuration);
    },
    stopAnimation() {
      if (this.animationInterval) {
        console.log("Stopping animation");
        clearInterval(this.animationInterval);
        this.animationInterval = null;
      } else {
        console.log("No animation to stop");
      }
    },
    handlePopState() {
      this.stopAudio();
    },
    beforeDestroy() {
      if (this.mediaStream) {
        this.mediaStream.getTracks().forEach((track) => track.stop());
      }
      this.stopAudio();
      window.removeEventListener("popstate", this.handlePopState);
      const audioElement = document.getElementById("audio-player");
      if (audioElement) {
        audioElement.removeEventListener("ended", this.audioEnded);
      }
      clearInterval(this.interval);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/color.scss";
.layout {
  display: grid;
  grid-template-columns: auto 1fr; /* Two columns: tabs and content */
}

.vertical-tabs {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 10px;
}

.tab-button {
  position: relative;
  z-index: 999;
  height: 96px;
  width: 100px;
  border-radius: 8px 0px 0px 8px;
  border: 3px solid $white-color;
  // padding: 10px 20px;
  color: $text-color;
  background-color: $qus-chat-body-color;
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  font-weight: 400;
  font-style: normal;
  transition: all 0.25s;
  display: flex;
  flex-direction: column; /* Arrange items vertically */
  align-items: center; /* Center image and text horizontally */
  justify-content: center; /* Center content vertically */
  text-align: center; /* Align text */
  font-size: 17px;
  line-height: 120%; /* 1.275rem */
  letter-spacing: -0.02125rem;
}
.tab-button.disabled-tab {
  pointer-events: none; /* Disable any interaction */
  cursor: not-allowed; /* Show 'not allowed' cursor when disabled */
  background-color: $qus-chat-body-color;
  // z-index: 997;
}

.tab-button.disabled-tab:hover {
  color: inherit !important; /* Prevent hover color change */
}

// Remove hover effect for Ask me anything
.tab-button.no-hover:hover {
  font-weight: 400;
}

.tab-button.active {
  // background-color: darken($tab-active-color, 15%);
  color: $text-color;
  z-index: 1000;
}

.tab-button:hover {
  // background-color: darken($tab-active-color, 15%);
  border-radius: 8px 0px 0px 8px;
  border: 3px solid $white-color;
  color: $black-color;
  //   font-size: 16px;
  z-index: 1001;
  font-weight: bold;
}
.home-tab {
  margin-top: auto; /* Pushes the Home tab to the bottom */
}

.tab-icon {
  width: 18px; /* Adjust size as needed */
  height: 18px;
  vertical-align: middle; /* Align icon with text */
}
.content {
  position: relative;
  background-color: $white-color;
  padding: 0px;
  border: 3px solid $white-color;
  border-radius: 0px 10px 10px 0px;
}
.playNpause {
  width: 5.4375rem;
  height: 5.4375rem;
  position: relative;
  bottom: 15px;
}
.qus-quiz-container {
  width: 100%; /* Full width of the parent container */
  aspect-ratio: 16 / 9; /* Maintain a 16:9 aspect ratio */
  background-color: $white-color; /* Optional background color */
  border: 3px solid $white-color;
}
.character-animation {
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
}
.feather-animation {
  position: absolute;
  bottom: 0px;
  left: 0px;
}
.flute-animation {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.question-controls {
  text-align: center;
  position: absolute;
  bottom: 10px;
  left: 0px;
}

.waiting-loader-position {
  display: contents;
}

.playNpause {
  width: 6rem;
  height: 6rem;
}

.stopButton {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  // left: 30px;
  bottom: 0px;
}

.fnff-peacock-left {
  width: 10rem;
  height: 8rem;
  position: absolute;
  bottom: 0px;
  left: 0px;
}
.fnff-peacock-right {
  width: 10rem;
  height: 8rem;
  position: absolute;
  bottom: 0px;
  right: -25px;
}

.question-container {
  background-color: $white-color;
  height: 100%;
  padding: 35px 35px 10px 35px;
  border-radius: 10px;
  position: relative;
}

.qustion-body {
  height: 100%;
  border-radius: 10px;
  // padding: 20px !important;
  font-size: 20px;
}

/* Add blur to the content container, but not the images */
/* Add blur and background color to the content container, but not the images */
.blur-content {
  filter: blur(2px);
  pointer-events: none; /* Disables interaction with content */
  opacity: 0.8; /* Optional: Adjust opacity to make blur more visible */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.question-play-n-pause {
  width: 6rem;
  height: 6rem;
}

.auth-overlay {
  background-color: rgb(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}
.prompt-block {
  max-width: 700px;
  margin: 30px auto 30px auto;
  max-height: 500px;
  display: inline-block;
  text-align: center;
  background-color: rgba(255, 255, 255, 1);
  padding: 30px;
  border-radius: 15px;

  h4 {
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }

  p {
    margin: 1rem 0;
  }

  > div + div {
    border-top: 1px solid lightgrey;
    margin-top: 1rem;
    padding-top: 1rem;
  }
}

.tooltip-container {
  position: relative;
  display: contents;
}

.tooltiptext {
  visibility: visible; /* Set to hidden if you want tooltip only on hover */
  min-width: 120px;
  font-size: 12px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 998; /* Set higher than the image */
  bottom: 100%; /* Position the tooltip above the image */
  left: 50%;
  transform: translateX(-50%); /* Center the tooltip horizontally */
  margin-bottom: 10px; /* Optional: Space between the tooltip and the image */
}

/* Optional: Show the tooltip only on hover */
.tooltip-container .tooltiptext {
  visibility: visible; /* Hide by default */
}

.tooltip-container:hover .tooltiptext {
  visibility: visible; /* Show on hover */
   background-color: $white-color;
  color: $black-color; /* Optional: Change tooltip text color */
  border: 1px solid $black-color;
}

.loading-image {
  height: 400px;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.control-stop-button {
  // position: absolute;
  // top: 89%;
  // left: 40%;
  transform: translate(-50%, -50%);
  width: 125px;
  height: 30px;
  z-index: 998;
  background-color: #ede6cf;
  border: none;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  left: 60px;
}

.control-button {
  // position: absolute;
  // top: 89%;
  // left: 55%;
  transform: translate(-50%, -50%);
  width: 125px;
  height: 30px;
  z-index: 998;
  background-color: #ede6cf;
  border: none;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  left: 60px;
}

.control-image {
  height: 25px;
}

.text-scroller-container {
  overflow-y: hidden; /* Hide overflowing text */
  line-height: 1.25; /* Adjust line height for better readability */
  position: relative;
  width: 100%;
  white-space: pre-wrap;
}

.text-scroller {
  transition: transform 1.5s ease-in-out; /* Smooth transition effect */
}

.text-scroller p {
  margin: 0;
}

.dummy-text {
  font-family: Arial;
  font-size: 17px; /* Default font size */
  line-height: 1.5; /* Default line height */
  margin: 0;
  padding: 0;
  word-wrap: break-word; /* Ensure long words break properly */
  overflow-wrap: break-word;
  display: block;
  max-width: 100%;
  box-sizing: border-box; /* Include padding/margins in width */
}
@media screen and (orientation: landscape) {
  /* Mobile devices (Portrait, very small screens) */
  @media (max-width: 400px) {
    .dummy-text {
      font-size: 12px;
      line-height: 21px;
    }
    .tab-button {
      font-size: 10px;
      width: 70px;
      height: 78px;
    }
    .text-scroller-container {
      height: 140px;
    }
  }

  /* Extra-Small mobile landscape (400px to 600px) */
  @media (min-width: 401px) and (max-width: 600px) {
    .dummy-text {
      font-size: 14px;
      line-height: 21px;
    }
    .tab-button {
      font-size: 12px;
      width: 70px;
      height: 78px;
    }
    .text-scroller-container {
      height: 160px;
    }
  }

  /* small mobile/tablet landscape (600px to 768px) */
  @media (min-width: 601px) and (max-width: 768px) {
    .dummy-text {
      font-size: 16px;
      line-height: 21px;
    }
    .tab-button {
      font-size: 12px;
      width: 70px;
      height: 78px;
    }
    .text-scroller-container {
      height: 180px;
    }
  }

  /* Tablet screens */
  @media (min-width: 769px) and (max-width: 1024px) {
    .text-scroller-container {
      height: 200px;
    }
    .tab-button {
      font-size: 12px;
       height: 70px;
      width: 96px;
    }
  }

  /* Medium-large screens */
  @media (min-width: 1025px) and (max-width: 1200px) {
    .text-scroller-container {
      height: 300px;
    }
    .tab-top-button {
      height: 119px;
    }
  }
  @media (min-width: 1201px) and (max-width: 1500px) {
    .text-scroller-container {
      height: 350px;
    }
     .tab-top-button {
      height: 119px;
    }
  }
  /* Ultra-large screens */
  @media (min-width: 1901px) {
    .text-scroller-container {
      height: 400px; /* Larger height for ultra-wide displays */
    }
     .tab-top-button {
      height: 119px;
    }
  }

  /* Large screens */
  @media (min-width: 1501px) and (max-width: 1900px) {
    .text-scroller-container {
      height: 400px; /* Suitable for large desktop screens */
    }
     .tab-top-button {
      height: 119px;
    }
  }
}

@media screen and (orientation: landscape) and (max-width: 812px) {
  /* iPhone SE, iPhone 8, iPhone X, XS, 11 Pro, 12 Mini, 13 Mini, etc. */
  .dummy-text {
    font-size: 16px;
    line-height: 21px;
  }
  .fnff-peacock-left {
    width: 7rem;
    height: 6.5rem;
  }
  .fnff-peacock-right {
    width: 7rem;
    height: 6.5rem;
  }
  .feather-animation {
    left: 0px;
  }
  .flute-animation {
    right: 0px;
  }
  .playNpause {
    width: 5rem;
    height: 5rem;
    bottom: 10px;
  }
  .question-play-n-pause {
    width: 5rem;
    height: 5rem;
  }
  .controls {
    bottom: 10px;
  }
  .question-controls {
    bottom: 0px;
  }
  // .tooltiptext {
  //   bottom: 90%;
  //   top: -40%;
  // }
  .stopButton {
    bottom: 0px;
  }
}

/* iPhones with medium widths and heights */
@media screen and (orientation: landscape) and (min-width: 813px) and (max-width: 896px) and (max-height: 414px) {
  /* iPhone X, XR, 11, 11 Pro Max, 12, 13, 14 */

  .dummy-text {
    font-size: 16px;
    line-height: 21px;
  }
  .fnff-peacock-left {
    width: 7rem;
    height: 6.5rem;
  }
  .fnff-peacock-right {
    width: 7rem;
    height: 6.5rem;
  }
  .feather-animation {
    left: 0px;
  }
  .flute-animation {
    right: 0px;
  }
  .playNpause {
    width: 5rem;
    height: 5rem;
    bottom: 10px;
  }
  .question-play-n-pause {
    width: 5rem;
    height: 5rem;
  }
  .controls {
    bottom: 10px;
  }
  .question-controls {
    bottom: 0px;
  }
  // .tooltiptext {
  //   bottom: 90%;
  //   top: -40%;
  // }
  .stopButton {
    bottom: 0px;
  }
}

/* For larger iPhones in landscape */
@media screen and (orientation: landscape) and (min-width: 897px) and (max-height: 430px) {
  /* iPhone 12 Pro Max, 13 Pro Max, 14 Pro Max, 14 Plus, 15 Plus and larger future devices */

  .dummy-text {
    font-size: 16px;
    line-height: 21px;
  }
  .fnff-peacock-left {
    width: 7rem;
    height: 6.5rem;
  }
  .fnff-peacock-right {
    width: 7rem;
    height: 6.5rem;
  }
  .feather-animation {
    left: 0px;
  }
  .flute-animation {
    right: 0px;
  }
  .playNpause {
    width: 5rem;
    height: 5rem;
    bottom: 10px;
  }
  .question-play-n-pause {
    width: 5rem;
    height: 5rem;
  }
  .controls {
    bottom: 10px;
  }
  .question-controls {
    bottom: 0px;
  }
  // .tooltiptext {
  //   bottom: 90%;
  //   top: -40%;
  // }
  .stopButton {
    bottom: 0px;
  }
}
</style>
