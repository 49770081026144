<template>
  <div class="container-fluid finish-setup-outer-container">
    <div class="row m-0 container-min-height finish-setup-padding">
      <div
        class="col-12 col-sm-12 col-md-12 col-xl-12 bg-white finish-setup-inner-container d-flex justify-content-center align-items-center flex-column"
      >
        <!-- Content Section -->
        <div
          class="row m-0 finish-setup-container d-flex align-items-center justify-content-center text-center"
        >
          <!-- Image Section -->
          <div class="col-12 p-0 d-flex">
            <img
              src="../assets/FeatherCharacter.png"
              alt="Peacock"
              class="peacock-image"
            />
            <div class="row m-0">
              <div class="col-12 p-0 text-1 d-flex align-items-end">
                {{ username }} {{ labels.FINISH_SETUP.FINISH_SETUP_TEXT }}
              </div>
              <div class="col-12 p-0 text-2">
                {{ labels.FINISH_SETUP.FINISH_SETUP_DESCRIPTION }}
              </div>
            </div>
          </div>
        </div>

        <!-- Button Section -->
        <div class="row m-0 mt-4 w-100">
          <div class="col-12 p-0">
            <button class="setup-button" @click="homeRedirect">
              {{ labels.FINISH_SETUP.FINISH_SETUP_BUTTON }}
            </button>
          </div>
        </div>

        <!-- Payment Text Section -->
        <div class="row m-0 mt-3">
          <div class="col-12 p-0 d-flex justify-content-center text-3">
            {{ labels.FINISH_SETUP.FINISH_SETUP_PAYMENT_TEXT }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LABELS } from "@/constants/labels";
export default {
  name: "FinishSetUp",
  data() {
    return {
      labels: LABELS,
      username: localStorage.getItem("username"),
    };
  },
  methods: {
    homeRedirect() {
      this.$emit("homeRedirect");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/color.scss";
.finish-setup-outer-container {
  background-color: $dashboard-content-bg-color;
}
.finish-setup-padding {
  padding: 30px;
}
.finish-setup-inner-container {
  // display: grid;
  // align-items: center;
  border-radius: 10px !important;
  padding: 25px 70px 25px 70px;
}
.finish-setup-container {
  min-height: 330px;
  padding: 20px 50px 20px 50px;
  border-radius: 10px;
  background: var(
    --intro-gradient-1,
    linear-gradient(0deg, #fff8e2 0.71%, #fff1c3 56.33%, #ffeaa6 99.97%)
  );
}
.peacock-image-position {
  display: flex;
  align-items: center;
  justify-content: end;
}
.peacock-image {
  width: 266px;
  height: 224px;
}
.text-1 {
  color: $text-color;
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  line-height: 2rem; /* 160% */
}
.text-2 {
  color: $text-color;
  font-family: Arial;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
}
.setup-button {
  width: 100%;
  color: $text-color;
  text-align: center;
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 1.2375rem */
  letter-spacing: -0.0225rem;
  border: none;
  background-color: $btn-color;
  border-radius: 10px;
  padding: 10px;
}
.text-3 {
  color: $text-color;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 1rem !important;
}
@media (max-width: 1000px) {
  .finish-setup-container {
    min-height: 194px;
    padding: 20px 30px 20px 30px;
  }
  .finish-setup-inner-container {
    padding: 20px 50px 20px 50px;
  }
  .peacock-image {
    height: 168px;
    width: 200px;
  }
  .text-1 {
    font-size: 15px;
  }
  .text-2 {
    font-size: 18px;
  }
  .setup-button {
    font-size: 14px;
  }
  .text-3 {
    font-size: 12px;
    padding-top: 0.25rem !important;
  }
}
</style>