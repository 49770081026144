<template>
  <div>
  <ValidatePasscodeComponent
      v-if="!isAuthenticated"
      @passcodeSuccess="onPasscodeSuccess"
    />
  <div v-else class="container-fluid dashboard-page">
    <div class="row m-0 container-min-height dashboard-padding">
      <div
        class="col-12 col-sm-12 col-md-12 col-xl-12 bg-white dashboard-container"
      >
        <!-- Header -->
        <div class="row m-0 mb-5">
          <div class="dashboard-title">Parent dashboard</div>
          <div class="menu-button ml-auto" @click="backToHome"><img class="pr-1" src="../../assets/Home_1.svg" alt="" />Home</div>
        </div>
        <!-- Tabs -->
        <div class="col-12 p-0">
          <div class="tabs-container">
            <ul class="nav nav-tabs">
              <li
                class="nav-item"
                v-for="(tab, index) in dashboardTabs"
                :key="index"
              >
                <a
                  class="nav-link"
                  :class="{ active: activeTab === tab.name }"
                  href="#"
                  @click.prevent="selectTab(tab.name, index)"
                >
                  {{ tab.label }}
                </a>
              </li>
            </ul>
            <div class="tab-indicator">
              <!-- Highlight the active tab's underline -->
              <div
                class="indicator-line"
                :style="{
                  width: activeTabWidth + 'px',
                  left: activeTabOffset + 'px',
                }"
              ></div>
            </div>
            <div class="tab-content">
              <!-- Dynamically render the component based on activeTab -->
              <component :is="activeComponent" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import SummaryComponent from "@/components/ParentDashboard/SummaryComponent.vue";
import ProfileComponent from "@/components/ParentDashboard/ProfileComponent.vue";
import SettingsComponent from "@/components/ParentDashboard/SettingsComponent.vue"; // Adjust path as needed
import HelpComponent from "@/components/ParentDashboard/HelpComponent.vue";
import ValidatePasscodeComponent from "../ParentPasscode/ValidatePasscodeComponent.vue";

export default {
  name: "ParentDashboardComponent",
  components: {
    SummaryComponent,
    ProfileComponent,
    SettingsComponent,
    HelpComponent,
    ValidatePasscodeComponent
  },
  data() {
    return {
      dashboardTabs: [
        { name: "summary", label: "Summary", component: "SummaryComponent" },
        // { name: "profile", label: "Profile", component: "ProfileComponent" },
        // { name: "settings", label: "Settings", component: "SettingsComponent" },
        { name: "help", label: "Help", component: "HelpComponent" },
      ],
      activeTab: "summary", // Default active tab
      activeTabIndex: 0, // Default index for indicator
      activeTabWidth: 0, // Width of the active tab
      activeTabOffset: 0, // Left offset of the active tab
            isAuthenticated: false, // Default state for authentication
    };
  },
  mounted() {
    const fromSetupPasscode = localStorage.getItem("from_setup_passcode");
    if (fromSetupPasscode) {
      // Bypass ValidatePasscodeComponent if coming from setup page
      this.isAuthenticated = true;
      localStorage.removeItem("from_setup_passcode"); // Clear the flag after use
    }

    if (this.isAuthenticated) {
      this.updateIndicatorPosition();
      window.addEventListener("resize", this.updateIndicatorPosition);
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateIndicatorPosition);
  },
  computed: {
    activeComponent() {
      // Find the component name from the active tab
      const activeTabObj = this.dashboardTabs.find(
        (tab) => tab.name === this.activeTab
      );
      return activeTabObj ? activeTabObj.component : null;
    },
  },
  methods: {
    selectTab(tabName, tabIndex) {
      this.activeTab = tabName;
      this.activeTabIndex = tabIndex;
      this.updateIndicatorPosition();
    },
    updateIndicatorPosition() {
      // Get the active tab element
      this.$nextTick(() => {
        const activeTabElement = this.$el.querySelector(".nav-link.active");
        if (activeTabElement) {
          this.activeTabWidth = activeTabElement.offsetWidth;
          this.activeTabOffset = activeTabElement.offsetLeft;
        }
      });
    },
    backToHome() {
      this.$router.push("/home");
    },
    onPasscodeSuccess() {
      // Callback for successful passcode entry
      this.isAuthenticated = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/color.scss";
.dashboard-page {
  background-color: rgba($dashboard-bg-color, 0.5);
}
.dashboard-padding {
    padding: 30px 25px 30px 25px;
}
.dashboard-container {
  padding: 30px !important;
  border-radius: 10px;
}
.dashboard-title {
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: $text-color;
}
.menu-button {
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.3px;
  border-radius: 10px;
  background: #ede7d2;
  border: none;
  width: 150px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.menu-button:hover {
  background-color: #e0dbc5;
}
.tabs-container {
  position: relative;
}
.nav-tabs {
  display: flex;
  justify-content: flex-start;
  gap: 5rem;
  padding: 0;
  list-style: none;
  border-bottom: none;
}

.nav-item {
  font-size: 16px;
  margin: 0px;
  padding: 0px;
}

.nav-link {
  text-decoration: none;
  border: none;
  padding: 0px !important;
  color: $text-color;
}

.nav-link.active {
  color: $text-color;
  font-weight: 700;
}

.nav-link:hover {
  color: #333 !important;
}
.tab-indicator {
  position: relative;
  height: 2px;
  background: var(--copy-color, #8b8a8a);
  opacity: 0.5;
  width: 100%;
}

.indicator-line {
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: var(--copy-color, #323232);
  transition: all 0.3s ease;
}
@media  (max-width: 960px) {
.dashboard-title {
  font-size: 22px;
}
}
</style>