import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
      funToipcLodingState: '', // Initial state
      isAudioResponse: '',
      subjectName: '',
      categoryName: '',
      topicName: '',
      subjects: JSON.parse(localStorage.getItem('subjects')) || [],
      passcode: localStorage.getItem('passcode') || "",
      // other state properties
    },
    mutations: {
      setFunToipcLodingState(state, status) {
        state.funToipcLodingState = status;
      },
      setIsAudioResponse(state, status) {
        state.isAudioResponse = status;
      },
      setSubjectName(state, payload) {
        state.subjectName = payload;
      },
      setCategoryName(state, payload) {
        state.categoryName = payload;
      },
      setTopicName(state, payload) {
        state.topicName = payload;
      },
      setPasscode(state, payload) {
        state.passcode = payload;
        localStorage.setItem('passcode', payload)
        },
      setSubjects(state, subjects) {
        state.subjects = subjects; // Update the subjects state
        localStorage.setItem('subjects', JSON.stringify(subjects));
      },
      clearSubjects(state) {
        state.subjects = [];
        localStorage.removeItem('subjects'); // Clear subjects from localStorage
      },
      // other mutations
    },
    actions: {
      updateFunToipcLodingState({ commit }, status) {

        commit('setFunToipcLodingState', status);
      },
      updateIsAudioResponse({ commit }, status) {
      commit('setIsAudioResponse', status);
    },
    updateSubjectName({ commit }, subjectName) {
      commit("setSubjectName", subjectName);
    },
    updateCategoryName({ commit }, categoryName) {
      commit("setCategoryName", categoryName);
    },
    updateTopicName({ commit }, topicName) {
      commit("setTopicName", topicName);
    },
    async fetchSubjects({ commit }) {
      try {
        const dataToSend = {
          user_email: localStorage.getItem("email"),
        };

        const response = await axios.post(
          `${process.env.VUE_APP_FLUTE_N_FEATHER_DEV_BASE_API_URL_DOMAIN}${process.env.VUE_APP_FLUTE_N_FEATHER_LIST_PARENT_SUBJECTS_DETAILS_URL_PATH}`,
          dataToSend
        );

        if (response.data && response.data.subjects) {
          commit('setSubjects', response.data.subjects); // Commit data to the store
        } else {
          console.error('Invalid API response:', response);
        }
      } catch (error) {
        console.error('Error fetching subjects:', error);
      }
    },
    clearSubjects({ commit }) {
      commit('clearSubjects'); // Clear subjects from the store and localStorage
    },
    savePasscode({ commit }, passcode) {
      commit("setPasscode", passcode);
    },
      // other actions
    },
    getters: {
      getSubjectName: (state) => state.subjectName,
      getCategoryName: (state) => state.categoryName,
      getTopicName: (state) => state.topicName,
      getSubjects(state) {
        return state.subjects; // Getter to access subjects
      },
      getPasscode: (state) => state.passcode,
    },
  });